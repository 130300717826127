import React from 'react'
import { Link, graphql } from 'gatsby'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import Layout from 'components/layout'
import PageHeader from 'components/page-header'

const _groupBy = require('lodash.groupby')
const _uniq = require('lodash.uniq')


class HistoryPage extends React.Component {

  state = {
    decadeSort: false
  };

  setDecade = (decade) => {
    this.setState({ 
      decadeSort: decade 
    }) 
    this.timelineEventsContainer.scrollLeft = 0
  }

  render() {
    let eventDecadeGroups = _groupBy(this.props.data.timelineEvents.edges, event => Math.floor(event.node.Year / 10) * 10)
    let eventDecades = _uniq(this.props.data.timelineEvents.edges.map(event => Math.floor(event.node.Year / 10) * 10).sort())
    
    return (
      <Layout>
        <PageHeader 
          title="Farm History" 
          subtitle={<><em>Growing with gratitude</em> for 50 years and counting</>}
          sizes={this.props.data.headerImage.childImageSharp.fluid} 
        />
          
        <div className="l-primary">
          <nav>
            <h3>Fundamentals</h3>
            <ul>
              <li><Link to={'/fundamentals/family-farming'}>Family Farming</Link></li>
              <li><Link to={'/fundamentals/farm-team'}>Farm Team</Link></li>
              <li><Link to={'/fundamentals/farm-history'}>Farm History</Link></li>
              <li>
                  <Link to={'/fundamentals/what-we-grow'}>What We Grow</Link>
                  <ul>
                    <li><Link to={'/fundamentals/what-we-grow/tomatoes'}>Tomatoes</Link></li>
                    <li><Link to={'/fundamentals/what-we-grow/grains'}>Grains</Link></li>
                  </ul>   
              </li>
              <li><Link to={'/fundamentals/sustainability'}>Sustainability</Link></li>
            </ul> 
          </nav>
          <section>
            <p>Howell Farms was founded in 1972 by David and Mary Howell.  They produced corn and soybeans on 300 rented acres using borrowed equipment and ran a small farrow-to-finish hog enterprise.  An interest in horticultural crop production – and a fire which destroyed the farrowing house – led to the establishment of an onsite, retail farm market in 1977. Apples, sweet corn, pumpkins, tomatoes and melons were among the multiple fruit and vegetable crops grown for sale in the farm market.  School tours and festivals were commonplace during the 25 years of retail sales.  Production of Red Gold processing tomatoes was added in 1995.  When sons Adam and Aaron returned from university to join the family business in the early 2000s, corn and soybean production grew along with a transformation to larger scale fruit and vegetable production.  The crop mix was reduced to those which could be produced efficiently and marketed profitably on larger acreages.</p>
            
            <p>During those transformative years, Adam and Aaron originated an investment company to purchase and operate a large farm in Bahia, Brazil.  Aaron and his wife, Katy, moved to Brazil to manage this farming company.  They steered the farm from bare land to a productive operation growing cotton and soybeans.  With this rigorous management the goals of the Brazilian venture were met and Aaron was able to return to the Indiana farm after five years.</p>

            <p>Howell Farms of Crossroads LLC emerged in 2013 as the operational entity for the farm.  It remains a diversified, family farming business headquartered in central Indiana.  Production includes Red Gold tomatoes along with the traditional corn, soybeans, malting barley and wheat for processing flour.  David and Mary continue to work at the farm but Adam and Aaron provide the intensive agronomic and economic management.</p>

            <p>Despite turbulent global events, we are excited about the future of production agriculture.  Rapid consolidation of the U.S. agriculture industry, shrinking farmland base, strong global demand for high protein diets, seed genetics and technological adaptations for agriculture are factors which present opportunities for farmers who are prepared and able to meet the demands of the new production age.  Carefully planned growth is a necessity which defines our perspective; it is not a means to satisfy egos.  We plan and work to responsibly outpace the competition in order to secure our place in the agricultural industry of the 21st century.</p>

            <p>The land we farm is revered as our most valuable natural resource to be cared for without exception.  Intensive sustainability is a goal which pervades naturally in every facet of planning and operations; we are driven to continually increase production efficiently in our model of a large commercial, family farm.  We believe this model will answer critical, global food demands of the future.</p>

            <p>The timeline below provides more detail of notable events in our nearly 50 year history.</p>
          </section>
        </div>

        <div className="timeline">
          <div className="timeline__controls">
          <div className="container">
            <h2>Historical Timeline</h2>
            <ul>
              <li 
                onClick={() => this.setDecade(false)}
                className={`${!this.state.decadeSort ? 'active' : ''}`}
              >All</li>
              {eventDecades.map(decade => (
                <li 
                  onClick={() => this.setDecade(decade)}
                  className={`${this.state.decadeSort === decade ? 'active' : ''}`}
                >{decade}s</li>
              ))}
            </ul>
          </div>
          </div>
          <div className="timeline__events" ref={div => this.timelineEventsContainer = div}>
            {eventDecades.map(decade => (
              <div className={`decade-container ${!this.state.decadeSort || this.state.decadeSort === decade ? 'visible' : 'hidden'}`} key={decade} id={decade}>
                {eventDecadeGroups[decade].map(({ node }) => (
                  <div className="timeline__events__event" key={node.Blurb}>
                    <time>{node.Year}</time>
                    <h3>{node.Headline}</h3>
                    <p>{node.Blurb}</p>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <p><FontAwesomeIcon icon={faInfoCircle} /> Scroll the timeline to the right to read through our history.</p>
        </div>
        
      </Layout>
    )
  }
}

export const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "farm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    timelineEvents: allTimelineXlsxSheet1 {
      edges {
        node {
          Year
          Headline
          Blurb
        }
      }
    }
  }
`

export default HistoryPage
